import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import Header from '@facilities/components/Header/Header';
import MissionsCalendar from '@facilities/components/MissionsCalendar/MissionsCalendar';
import TasksAlert from '@facilities/components/TasksAlert/TasksAlert';
import useGetFacility from '@facilities/hooks/useGetFacility';
import containersStyles from '@facilities/views/containers.module.css';
import pageStyles from '@facilities/views/Home/Home.module.css';

const Home = () => {
  const { t } = useTranslation('app', { keyPrefix: 'facilities' });

  const { facilityId } = useParams<{ facilityId: string }>();

  const { data: facility, isSuccess, isLoading, isError } = useGetFacility(facilityId);

  const planningTitle = t('home.planning.title');

  const getContent = () => {
    return (
      <IonContent className="ion-padding">
        <div className={containersStyles.fullWidthFlexContainer}>
          <TasksAlert />
          <div className={pageStyles.planningContainer}>
            <h2 className={pageStyles.planningTitle}>{planningTitle}</h2>
            {isError && <p className="ion-padding">{t('home.planning.errorMessage')}</p>}
            {isSuccess && <MissionsCalendar facilityId={facilityId} />}
          </div>
        </div>
      </IonContent>
    );
  };

  return (
    <IonPage>
      <Header title={t('common.menu.home')} facility={facility} />
      {isLoading && <IonSpinner />}
      {isSuccess && getContent()}
    </IonPage>
  );
};

export default Home;
