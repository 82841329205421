import { queryOptions } from '@tanstack/react-query';

import fetchRequests, { Request } from '@facilities/services/fetchRequests';

const requestQueries = {
  all: () => ['requests'],

  lists: (facilityId: string) => [...requestQueries.all(), 'list', facilityId],
  list: (facilityId: string) =>
    queryOptions({
      queryKey: [...requestQueries.lists(facilityId)],
      queryFn: ({ signal }) => fetchRequests(facilityId, signal),
      gcTime: 5000,
    }),

  missions: (facilityId: string) =>
    queryOptions({
      queryKey: [...requestQueries.all(), 'missions', facilityId],
      queryFn: ({ signal }) => fetchRequests(facilityId, signal),
      gcTime: 5000,
      select: (data: Request[]) =>
        data.filter(
          (request) => !!request.assignedCarerFullName && !!request.missionCarerConfirmedAt,
        ),
    }),

  tasks: (facilityId: string) =>
    queryOptions({
      queryKey: [...requestQueries.lists(facilityId)],
      queryFn: ({ signal }) => fetchRequests(facilityId, signal),
      gcTime: 5000,
      select: (data: Request[]) =>
        data.filter(
          (request) =>
            request.status === 'Soignant à valider' &&
            !!request.assignedCarerFullName &&
            !request.missionCarerConfirmedAt,
        ),
    }),
};

export default requestQueries;
