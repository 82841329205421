import CarersRouteId from '@carers/routes/route';
import ProfileIcon from '@shared/assets/icons/account_circle.svg';
import MissionsIcon from '@shared/assets/icons/content_paste.svg';
import ActivityIcon from '@shared/assets/icons/tabler_activity.svg';
import RequestsIcon from '@shared/assets/icons/work_outline.svg';
import { AppRoute } from '@shared/routes/route';

const tabsBasePath = '/carers';

type TabRoute = AppRoute<CarersRouteId> & {
  icon: string;
};

const CARERS_TABS_ROOT_ROUTES: TabRoute[] = [
  {
    id: 'activity',
    path: `${tabsBasePath}/activity`,
    icon: ActivityIcon,
    label: 'carers.common.menu.activity',
  },
  {
    id: 'requestsList',
    path: `${tabsBasePath}/requests`,
    icon: RequestsIcon,
    label: 'carers.common.menu.requests',
  },
  {
    id: 'missionsList',
    path: `${tabsBasePath}/missions`,
    icon: MissionsIcon,
    label: 'carers.common.menu.missions',
  },
  {
    id: 'profile',
    path: `${tabsBasePath}/profile`,
    icon: ProfileIcon,
    label: 'carers.common.menu.profile',
  },
];

const CARERS_MAIN_ROUTES: AppRoute<CarersRouteId>[] = [
  ...CARERS_TABS_ROOT_ROUTES,
  {
    id: 'missionDetail',
    path: `${tabsBasePath}/missions/:id`,
    label: 'carers.missions.detail.title',
  },
  {
    id: 'requestDetail',
    path: `${tabsBasePath}/requests/:id`,
    label: 'carers.requests.detail.title',
  },
  {
    id: 'punctualAvailabilitiesForm',
    path: `${tabsBasePath}/punctual-availabilities`,
    label: 'carers.profile.actions.availabilities',
  },
];

export { CARERS_TABS_ROOT_ROUTES, CARERS_MAIN_ROUTES };
