import { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router';

import { CARERS_TABS_ROOT_ROUTES } from '@carers/routes/routes';
import { FACILITIES_MAIN_ROUTES } from '@facilities/routes/routes';

const isPathInTabsRoot = (pathname: string): boolean =>
  !![...CARERS_TABS_ROOT_ROUTES, ...FACILITIES_MAIN_ROUTES].find((route) =>
    matchPath(pathname, { path: route.path, exact: true }),
  );

const useDisplayTabBar = (isLargeScreen: boolean) => {
  const location = useLocation();
  const [isVisible, setVisible] = useState(isPathInTabsRoot(location.pathname));

  useEffect(() => {
    setVisible(isPathInTabsRoot(location.pathname));
  }, [location.pathname]);

  return isLargeScreen ? true : isVisible;
};

export default useDisplayTabBar;
