const RETOOL_APP_PUBLIC_URL = 'https://retool.mediflash.fr/p';

const REQUEST_CREATION_FORM_WIDGET_URL = `${RETOOL_APP_PUBLIC_URL}/facilities-create-request`;

const REQUESTS_LIST_WIDGET_URL = `${RETOOL_APP_PUBLIC_URL}/facilities-requests`;

const REQUEST_DETAIL_WIDGET_URL = `${RETOOL_APP_PUBLIC_URL}/facilities-request-detail`;

const TASKS_LIST_WIDGET_URL = `${RETOOL_APP_PUBLIC_URL}/facilities-tasks`;

const CARERS_WIDGET_URL = `${RETOOL_APP_PUBLIC_URL}/facilities-carers`;

const PREFERENCES_WIDGET_URL = `${RETOOL_APP_PUBLIC_URL}/facilities-preferences`;

export {
  CARERS_WIDGET_URL,
  PREFERENCES_WIDGET_URL,
  REQUEST_CREATION_FORM_WIDGET_URL,
  REQUEST_DETAIL_WIDGET_URL,
  REQUESTS_LIST_WIDGET_URL,
  TASKS_LIST_WIDGET_URL,
};
