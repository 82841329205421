import {
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import { useId, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import CardMission from '@carers/components/CardMission/CardMission';
import CardsMissionsSkeleton from '@carers/components/CardMission/CardsMissionsSkeleton';
import GridCards from '@carers/components/GridCards/GridCards';
import HeaderIcon from '@carers/components/HeaderIcon/HeaderIcon';
import ListFilterButton from '@carers/components/ListFilterButton/ListFilterButton';
import { Modal } from '@carers/components/Modal';
import PageContentWithSidePanel from '@carers/components/PageContentWithSidePanel/PageContentWithSidePanel';
import useRestoreScrollPosition from '@carers/hooks/useRestoreScrollPosition';
import { useSearchParamFilter } from '@carers/hooks/useSearchParamFilter';
import useGetMissions from '@carers/views/Missions/hooks/useGetMissions';
import MissionFilterSegment from '@carers/views/Missions/MissionsList/components/MissionFilterSegment/MissionFilterSegment';
import MissionsFilter from '@carers/views/Missions/MissionsList/components/MissionsFilter/MissionsFilter';
import {
  MISSIONS_FILTER_KEYS,
  MISSIONS_FILTER_KEYS_INTERNAL,
  MissionsFilters,
} from '@carers/views/Missions/models/MissionsFilters';
import NotFoundImage from '@shared/assets/images/status/not-found.svg';
import BlankSlate from '@shared/components/BlankSlate/BlankSlate';
import BlankSlateError from '@shared/components/BlankSlate/BlankSlateError';
import Refresher from '@shared/components/Refresher/Refresher';
import useIsScreenXL from '@shared/hooks/useIsScreenXL';

const Missions = () => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.missions.list' });
  const isScreenXL = useIsScreenXL();
  const router = useIonRouter();
  const filterModal = useRef<HTMLIonModalElement>(null);
  const pageIonContent = useRef<HTMLIonContentElement>(null);
  const filterModalId = useId();
  const { filters, applyFilters } = useSearchParamFilter<MissionsFilters>();

  const internalFilters = Object.fromEntries(
    Object.entries(filters).filter(([key]) =>
      MISSIONS_FILTER_KEYS_INTERNAL.includes(key as MISSIONS_FILTER_KEYS),
    ),
  );

  const sideFilters = Object.fromEntries(
    Object.entries(filters).filter(
      ([key]) => !MISSIONS_FILTER_KEYS_INTERNAL.includes(key as MISSIONS_FILTER_KEYS),
    ),
  );

  const sideFiltersCount = Object.keys(sideFilters).length;
  const hasSideFilters = sideFiltersCount > 0;

  const {
    fetchNextPage,
    refetch,
    hasNextPage,
    isLoading,
    isError,
    isRefetchError,
    error,
    data: missionsData,
  } = useGetMissions(filters);

  const { saveScrollPosition } = useRestoreScrollPosition(pageIonContent);

  const hasNoData = !missionsData || missionsData.pages.length === 0;

  const shouldDisplayFilterButton = !isScreenXL && !isError && (!hasNoData || hasSideFilters);

  const handleSubmitFilter = async (newFilters: MissionsFilters) => {
    await filterModal.current?.dismiss();
    applyFilters({ ...newFilters, ...internalFilters });
    await pageIonContent.current?.scrollToTop();
  };

  const handleIsPastFilterChange = (value: string) => {
    applyFilters({ ...filters, isPast: value });
  };

  const goToRequests = () => {
    router.push(`/carers/requests`);
  };

  const unselectFilters = () => {
    applyFilters({ ...internalFilters });
  };

  const getNoDataBlankSlate = () => {
    if (hasSideFilters) {
      return {
        message:
          filters.isPast === 'true'
            ? t('messages.errors.noDataPastWithFilters')
            : t('messages.errors.noDataToComeWithFilters'),
        action: unselectFilters,
        actionName: 'actions.unselectFilters',
      };
    }

    return {
      message:
        filters.isPast === 'true'
          ? t('messages.errors.noDataPast')
          : t('messages.errors.noDataToCome'),
      action: goToRequests,
      actionName: 'actions.showRequests',
    };
  };

  const getContentMissions = () => {
    if (hasNoData) {
      if (isError) {
        return <BlankSlateError error={error} action={refetch} />;
      }

      if (!isLoading) {
        const blankSlate = getNoDataBlankSlate();

        return (
          <>
            <MissionFilterSegment
              onChange={handleIsPastFilterChange}
              value={internalFilters.isPast as string}
            />
            <BlankSlate
              message={blankSlate.message}
              image={NotFoundImage}
              action={blankSlate.action}
              actionName={t(blankSlate.actionName)}
            />
          </>
        );
      }
    }

    return (
      <>
        <MissionFilterSegment
          onChange={handleIsPastFilterChange}
          value={internalFilters.isPast as string}
        />
        <GridCards>
          {isLoading ? (
            <CardsMissionsSkeleton />
          ) : (
            <>
              {missionsData!.pages.map((mission) => (
                <CardMission
                  mission={mission}
                  key={mission.id}
                  saveScrollPosition={saveScrollPosition}
                />
              ))}
              {isRefetchError && (
                <IonText
                  color="danger"
                  className="ion-padding"
                  onClick={async () => fetchNextPage()}
                >
                  {t('messages.errors.infiniteScroll')}
                </IonText>
              )}
            </>
          )}
        </GridCards>
        <IonInfiniteScroll
          disabled={!hasNextPage}
          onIonInfinite={async (event) => {
            await fetchNextPage();
            await event.target.complete();
          }}
        >
          <IonInfiniteScrollContent />
        </IonInfiniteScroll>
      </>
    );
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HeaderIcon slot="start" />
          <IonTitle>{t('title')}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <PageContentWithSidePanel
        sidePanel={<MissionsFilter onSubmit={handleSubmitFilter} appliedFilters={sideFilters} />}
      >
        <IonContent
          className={`ion-padding ${(!hasNoData || isLoading) && 'withBlob'}`}
          ref={pageIonContent}
          scrollEvents
        >
          <Refresher refetch={refetch} />
          {getContentMissions()}

          {shouldDisplayFilterButton && (
            <>
              <ListFilterButton
                isDisabled={isLoading}
                buttonId={filterModalId}
                filtersCount={sideFiltersCount}
              />
              <Modal
                ref={filterModal}
                trigger={filterModalId}
                breakpoints={[0, 1]}
                initialBreakpoint={1}
                aria-labelledby="filter-modal-title"
              >
                <MissionsFilter onSubmit={handleSubmitFilter} appliedFilters={sideFilters} />
              </Modal>
            </>
          )}
        </IonContent>
      </PageContentWithSidePanel>
    </IonPage>
  );
};

export default Missions;
