import { useTranslation } from 'react-i18next';

const formatOptions: Intl.NumberFormatOptions = {
  currency: 'EUR',
  currencyDisplay: 'narrowSymbol',
  maximumFractionDigits: 2,
};

const useFormatRemuneration = (
  grossRemuneration: number | null,
  netRemuneration: number | null,
  minimumGrossRemuneration: number | null,
): { gross: string; net?: string } => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.common.format' });

  const formattedGross = grossRemuneration ? grossRemuneration / 100 : null;
  const formattedNet = netRemuneration ? netRemuneration / 100 : null;

  if (formattedNet) {
    return {
      gross: t('gross', { gross: formattedGross, ...formatOptions }),
      net: t('net', { net: formattedNet, ...formatOptions }),
    };
  }

  if (!formattedGross && !!minimumGrossRemuneration) {
    const formattedMinimumGrossRemuneration = minimumGrossRemuneration / 100;

    return {
      gross: t('gross', { gross: formattedMinimumGrossRemuneration, ...formatOptions }),
    };
  }

  return { gross: t('gross', { gross: formattedGross, ...formatOptions }) };
};

export default useFormatRemuneration;
