import { RefObject, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

interface ScrollPosition {
  x: number;
  y: number;
}

const useScrollPosition = (ionContentRef: RefObject<HTMLIonContentElement>) => {
  const location = useLocation();

  const [currentScrollPosition, setCurrentScrollPosition] = useState<ScrollPosition>({
    x: 0,
    y: 0,
  });

  const [savedScrollPosition, setSavedScrollPosition] = useState<ScrollPosition>({
    x: 0,
    y: 0,
  });

  const saveScrollPosition = () => {
    setSavedScrollPosition(currentScrollPosition);
  };

  const restoreScrollPosition = useCallback(async () => {
    // @sampirat79: Need to wait until page load only for iOS
    setTimeout(async () => {
      const { x, y } = savedScrollPosition;
      await ionContentRef.current?.scrollToPoint(x, y);
    }, 500);
  }, [savedScrollPosition]);

  useEffect(() => {
    const content = ionContentRef.current;

    if (!content) {
      return () => {};
    }

    const handleScroll = async () => {
      const scrollElement = await content.getScrollElement();
      setCurrentScrollPosition({
        x: scrollElement.scrollLeft || 0,
        y: scrollElement.scrollTop || 0,
      });
    };

    content.addEventListener('ionScroll', handleScroll);

    restoreScrollPosition().catch(() => {});

    return () => {
      content.removeEventListener('ionScroll', handleScroll);
    };
  }, [location]);

  return { saveScrollPosition };
};

export default useScrollPosition;
