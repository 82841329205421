import { queryOptions } from '@tanstack/react-query';

import fetchMissions from '@facilities/services/fetchMissions';

const missionQueries = {
  all: () => ['missions'],

  lists: (facilityId: string) => [...missionQueries.all(), 'list', facilityId],
  list: (facilityId: string) =>
    queryOptions({
      queryKey: [...missionQueries.lists(facilityId)],
      queryFn: ({ signal }) => fetchMissions(facilityId, signal),
      gcTime: 5000,
    }),
};

export default missionQueries;
