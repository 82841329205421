import { GenericAbortSignal } from 'axios';

import { httpService } from '@shared/httpService';

type RequestDTO = {
  id: string;
  status: string;
  job: string;
  contract: string;
  assignedCarerFullName: string | null;
  missionCarerConfirmedAt: string | null;
};

type Request = {
  id: string;
  status: string;
  job: string;
  contract: string;
  assignedCarerFullName: string | null;
  missionCarerConfirmedAt: string | null;
};

const mapRequestDTOToRequest = (dto: RequestDTO): Request => {
  return {
    id: dto.id,
    status: dto.status,
    job: dto.job,
    contract: dto.contract,
    assignedCarerFullName: dto.assignedCarerFullName,
    missionCarerConfirmedAt: dto.missionCarerConfirmedAt,
  };
};

const mapRequestsDTOToRequests = (dtos: RequestDTO[]): Request[] => {
  return dtos.map(mapRequestDTOToRequest);
};

const { VITE_FACILITIES_MISSIONS_CALENDAR_OUTBOUND_API_KEY } = import.meta.env;

const fetchRequests = async (facilityId: string, signal: GenericAbortSignal) => {
  const url = `/facilities/${facilityId}/requests`;

  const config = {
    headers: {
      'X-API-KEY': VITE_FACILITIES_MISSIONS_CALENDAR_OUTBOUND_API_KEY,
    },
    signal,
  };

  return httpService
    .get<RequestDTO[]>(url, config)
    .then((response) => mapRequestsDTOToRequests(response.data));
};

export default fetchRequests;

export type { Request };
