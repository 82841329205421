import { EventContentArg } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { IonButton, IonCard, IonCardContent, IonIcon, IonLabel, useIonPopover } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import MissionEventDetails from '@facilities/components/MissionsCalendar/MissionEventDetails';
import { DaySchedule, Mission } from '@facilities/services/fetchMissions';
import dayIcon from '@shared/assets/icons/day.svg';
import nightIcon from '@shared/assets/icons/night.svg';

type MissionEventContentArg = EventContentArg & {
  event: EventImpl & { extendedProps: { mission: Mission; schedule: DaySchedule } };
};

const MissionEventDayGridCard = ({ eventInfo }: { eventInfo: MissionEventContentArg }) => {
  const { t } = useTranslation('app', { keyPrefix: 'facilities' });

  const { mission, schedule } = eventInfo.event.extendedProps;
  const rythmIcon = schedule.isNight ? nightIcon : dayIcon;

  const [present, dismiss] = useIonPopover(MissionEventDetails, {
    eventInfo,
    onDismiss: (data: unknown, role: string) => dismiss(data, role),
  });

  const openPopover = () => present();

  return (
    <IonCard onClick={openPopover}>
      <IonCardContent className="fc-event-main-frame">
        <IonIcon slot="icon-only" aria-hidden icon={rythmIcon} />
        <IonLabel className="fc-event-title">{mission.assignedCarerFullName}</IonLabel>
        <IonLabel className="mission-job">{mission.job}</IonLabel>
        <IonLabel className="fc-event-time">{schedule.time}</IonLabel>
        <IonLabel className="mission-contract">{mission.contract}</IonLabel>
        <IonButton fill="clear">{t('home.calendar.action.details')}</IonButton>
      </IonCardContent>
    </IonCard>
  );
};

export default MissionEventDayGridCard;
