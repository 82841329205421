import { EventContentArg } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { IonIcon, useIonPopover } from '@ionic/react';

import MissionEventDetails from '@facilities/components/MissionsCalendar/MissionEventDetails';
import { DaySchedule, Mission } from '@facilities/services/fetchMissions';
import dayIcon from '@shared/assets/icons/day.svg';
import nightIcon from '@shared/assets/icons/night.svg';

type MissionEventContentArg = EventContentArg & {
  event: EventImpl & { extendedProps: { mission: Mission; schedule: DaySchedule } };
};

const MissionEventList = ({ eventInfo }: { eventInfo: MissionEventContentArg }) => {
  const { mission, schedule } = eventInfo.event.extendedProps;
  const icon = schedule.isNight ? nightIcon : dayIcon;

  const [present, dismiss] = useIonPopover(MissionEventDetails, {
    eventInfo,
    onDismiss: (data: unknown, role: string) => dismiss(data, role),
  });

  const openPopover = () => present();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className="fc-event-main-frame" onClick={openPopover}>
      <IonIcon slot="icon-only" aria-hidden icon={icon} />
      <div className="fc-event-time">{schedule.time}</div>
      <div className="fc-event-title">{mission.assignedCarerFullName}</div>
      <div className="mission-job">{mission.job}</div>
      <div className="mission-contract">{mission.contract}</div>
    </div>
  );
};

export default MissionEventList;
