import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import Header from '@facilities/components/Header/Header';
import RetoolWidgetContainer from '@facilities/components/RetoolWidgetContainer/RetoolWidgetContainer';
import {
  REQUEST_DETAIL_WIDGET_URL,
  TASKS_LIST_WIDGET_URL,
} from '@facilities/constants/retoolConstants';
import UUID_V4_REGEX from '@facilities/constants/urlConstants';
import useFetchTasks from '@facilities/hooks/useFetchTasks';
import useGetFacility from '@facilities/hooks/useGetFacility';
import { ANALYTICS_LOG_EVENTS } from '@facilities/utils/Analytics';
import formatRetoolWidgetUrl from '@facilities/utils/utils-fn';
import containersStyles from '@facilities/views/containers.module.css';
import RetoolWidget from '@facilities/views/RetoolWidget/RetoolWidget';
import useIsScreenXL from '@shared/hooks/useIsScreenXL';
import { logEvent } from '@shared/utils/Analytics';

type SelectTaskHandleDataType = { taskMissionId: string };

enum TaskActionDataType {
  DISMISS = 'dismiss',
  RELOAD = 'reload',
  CONFIRM = 'confirm',
  CANCEL = 'cancel',
}
type TaskActionHandleDataType = { action?: TaskActionDataType };

const Tasks = () => {
  const { t } = useTranslation('app', { keyPrefix: 'facilities' });
  const history = useHistory();

  const { facilityId, missionId } = useParams<{
    facilityId: string;
    missionId: string;
  }>();
  const { data: facility, isSuccess, isLoading } = useGetFacility(facilityId);
  const { refetch: refetchTasks } = useFetchTasks(facilityId);

  const isScreenXL = useIsScreenXL();

  const initialMissionId = UUID_V4_REGEX.test(missionId) ? missionId : null;
  const [selectedMissionId, setSelectedMissionId] = useState<string | null>(initialMissionId);
  const [lastReloadAskedAt, setLastReloadAskedAt] = useState<number>(new Date().getTime());

  const shouldDisplayTaskInfo = !!selectedMissionId;

  const handleSelectMission = ({ taskMissionId }: SelectTaskHandleDataType) => {
    if (!!taskMissionId) {
      setSelectedMissionId(taskMissionId);
    }
  };

  const handleTaskAction = async ({ action }: TaskActionHandleDataType) => {
    switch (action) {
      case TaskActionDataType.DISMISS:
        setSelectedMissionId(null);

        break;
      case TaskActionDataType.RELOAD:
        setLastReloadAskedAt(new Date().getTime());

        break;
      case TaskActionDataType.CANCEL:
        setLastReloadAskedAt(new Date().getTime());
        setSelectedMissionId(null);

        break;
      case TaskActionDataType.CONFIRM:
        void logEvent(ANALYTICS_LOG_EVENTS.CONFIRM_TASK_CLICK);
        setLastReloadAskedAt(new Date().getTime());
        await refetchTasks();

        break;
      default:
    }
  };

  useEffect(() => {
    const currentPath = history.location.pathname;
    const tasksPath = !selectedMissionId ? `/tasks` : `/tasks/${selectedMissionId}`;
    const newPath = currentPath.replace(/\/tasks.*/, tasksPath);

    window.history.pushState({}, '', newPath);
  }, [selectedMissionId, history.location.pathname]);

  const tasksListUrl = formatRetoolWidgetUrl(TASKS_LIST_WIDGET_URL, { facilityId });
  const tasksWidget = (
    <RetoolWidget
      url={tasksListUrl}
      handleData={handleSelectMission}
      data={{ selectedId: selectedMissionId, lastReloadAskedAt }}
    />
  );

  const taskUrl = formatRetoolWidgetUrl(REQUEST_DETAIL_WIDGET_URL, {
    facilityId,
    missionId: selectedMissionId,
  });
  const taskWidget = <RetoolWidget url={taskUrl} handleData={handleTaskAction} />;

  const shouldShowTaskInfoBelowTasksList = !isScreenXL;

  const getContent = () => {
    return (
      <IonContent className="ion-padding">
        <div className={containersStyles.fullWidthFlexContainer}>
          <div className={containersStyles.fullHeightFlexContainer}>
            <RetoolWidgetContainer
              retoolWidget={tasksWidget}
              width={shouldDisplayTaskInfo && !shouldShowTaskInfoBelowTasksList ? 60 : 100}
            />
            {shouldDisplayTaskInfo && !shouldShowTaskInfoBelowTasksList && (
              <RetoolWidgetContainer retoolWidget={taskWidget} width={40} />
            )}
          </div>

          {shouldDisplayTaskInfo && shouldShowTaskInfoBelowTasksList && (
            <div className={containersStyles.fullHeightFlexContainer}>
              <RetoolWidgetContainer retoolWidget={taskWidget} width={100} />
            </div>
          )}
        </div>
      </IonContent>
    );
  };

  return (
    <IonPage>
      <Header title={t('common.menu.tasks')} facility={facility} />
      {isLoading && <IonSpinner />}
      {isSuccess && getContent()}
    </IonPage>
  );
};

export default Tasks;
