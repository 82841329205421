const { VITE_RETOOL_ENV } = import.meta.env;

type RetoolWidgetUrlParams = {
  facilityId?: string;
  requestId?: string | null;
  missionId?: string | null;
  selectedCarers?: string | null;
};

const formatRetoolWidgetUrl = (
  baseUrl: string,
  { facilityId, requestId, missionId, selectedCarers }: RetoolWidgetUrlParams,
) => {
  const environmentQueryParam = { _environment: VITE_RETOOL_ENV };
  const queryParams = new URLSearchParams(environmentQueryParam);

  if (!!facilityId) {
    queryParams.append('facilityId', facilityId);
  }

  if (!!requestId) {
    queryParams.append('requestId', requestId);
  }

  if (!!missionId) {
    queryParams.append('missionId', missionId);
  }

  if (!!selectedCarers) {
    queryParams.append('selectedCarers', selectedCarers);
  }

  return `${baseUrl}?${queryParams.toString()}`;
};

export default formatRetoolWidgetUrl;
