import FacilitiesRouteId from '@facilities/routes/route';
import CalendarIcon from '@shared/assets/icons/calendar.svg';
import CarersIcon from '@shared/assets/icons/carers.svg';
import TasksIcon from '@shared/assets/icons/content_paste.svg';
import PreferencesIcon from '@shared/assets/icons/preferences.svg';
import ActivityIcon from '@shared/assets/icons/tabler_activity.svg';
import RequestIcon from '@shared/assets/icons/work_outline.svg';
import { AppRoute } from '@shared/routes/route';

const getTabsBasePath = (facilityId: string) => `/facilities/${facilityId}`;
const FACILITY_ID_PARAM = ':facilityId';
const REQUEST_ID_PARAM = ':missionId';

type TabRoute = AppRoute<FacilitiesRouteId> & {
  icon: string;
  href: (facilityId: string) => string;
  exact: boolean; // eslint-disable-line @typescript-eslint/naming-convention
};

type MainRoute = AppRoute<FacilitiesRouteId> & {
  icon: string;
  href: (facilityId: string, requestId: string) => string;
  exact: boolean; // eslint-disable-line @typescript-eslint/naming-convention
};

const FACILITIES_TABS_ROOT_ROUTES: TabRoute[] = [
  {
    id: 'home',
    path: `${getTabsBasePath(FACILITY_ID_PARAM)}/home`,
    href: (facilityId: string) => `${getTabsBasePath(facilityId)}/home`,
    icon: ActivityIcon,
    label: 'facilities.common.menu.home',
    exact: true,
  },
  {
    id: 'newRequest',
    path: `${getTabsBasePath(FACILITY_ID_PARAM)}/new-request`,
    href: (facilityId: string) => `${getTabsBasePath(facilityId)}/new-request`,
    icon: RequestIcon,
    label: 'facilities.common.menu.newRequest',
    exact: true,
  },
  {
    id: 'requests',
    path: `${getTabsBasePath(FACILITY_ID_PARAM)}/requests`,
    href: (facilityId: string) => `${getTabsBasePath(facilityId)}/requests`,
    icon: CalendarIcon,
    label: 'facilities.common.menu.requests',
    exact: true,
  },
  {
    id: 'tasks',
    path: `${getTabsBasePath(FACILITY_ID_PARAM)}/tasks`,
    href: (facilityId: string) => `${getTabsBasePath(facilityId)}/tasks`,
    icon: TasksIcon,
    label: 'facilities.common.menu.tasks',
    exact: true,
  },
  {
    id: 'carers',
    path: `${getTabsBasePath(FACILITY_ID_PARAM)}/carers`,
    href: (facilityId: string) => `${getTabsBasePath(facilityId)}/carers`,
    icon: CarersIcon,
    label: 'facilities.common.menu.carers',
    exact: true,
  },
  {
    id: 'preferences',
    path: `${getTabsBasePath(FACILITY_ID_PARAM)}/preferences`,
    href: (facilityId: string) => `${getTabsBasePath(facilityId)}/preferences`,
    icon: PreferencesIcon,
    label: 'facilities.common.menu.preferences',
    exact: true,
  },
];

const FACILITIES_MAIN_ROUTES: MainRoute[] = [
  ...FACILITIES_TABS_ROOT_ROUTES,
  {
    id: 'requestDetail',
    path: `${getTabsBasePath(FACILITY_ID_PARAM)}/requests/${REQUEST_ID_PARAM}`,
    href: (facilityId: string, missionId: string) =>
      `${getTabsBasePath(facilityId)}/requests/${missionId}`,
    icon: TasksIcon,
    label: 'facilities.common.menu.requests.details',
    exact: false,
  },
  {
    id: 'taskDetail',
    path: `${getTabsBasePath(FACILITY_ID_PARAM)}/tasks/${REQUEST_ID_PARAM}`,
    href: (facilityId: string, missionId: string) =>
      `${getTabsBasePath(facilityId)}/tasks/${missionId}`,
    icon: TasksIcon,
    label: 'facilities.common.menu.tasks.details',
    exact: false,
  },
];

export { FACILITIES_MAIN_ROUTES, FACILITIES_TABS_ROOT_ROUTES };
