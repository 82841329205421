import { GenericAbortSignal } from 'axios';

import { httpService } from '@shared/httpService';

type DayScheduleDto = {
  start: string;
  end: string;
  breakDuration: number;
};

type MissionDTO = {
  id: string;
  code: string;
  status: string;
  job: string;
  contract: string;
  service: string;
  unit: string;
  daySchedules: DayScheduleDto[];
  planning: string;
  assignedCarerFullName: string;
  totalPrice: number;
  feeQuoteUrl: string | null;
  serviceDeliveryContractUrl: string | null;
  serviceDeliveryQuoteUrl: string | null;
  feeInvoiceUrl: string | null;
  serviceDeliveryInvoiceUrl: string | null;
};

type DaySchedule = {
  start: Date;
  end: Date;
  time: string;
  breakDuration: number;
  isNight: boolean;
};

type Mission = {
  id: string;
  code: string;
  status: string;
  job: string;
  contract: 'Vacation' | 'Indépendant';
  service: string;
  unit: string;
  daySchedules: DaySchedule[];
  planning: string;
  assignedCarerFullName: string;
  totalPrice: number;
  feeQuoteUrl: string | null;
  serviceDeliveryContractUrl: string | null;
  serviceDeliveryQuoteUrl: string | null;
  feeInvoiceUrl: string | null;
  serviceDeliveryInvoiceUrl: string | null;
  styles: {
    backgroundColor: string;
  };
};

const mapDayScheduleDtoToDaySchedule = (dto: DayScheduleDto): DaySchedule => {
  const { breakDuration } = dto;
  const start = new Date(dto.start);
  const end = new Date(dto.end);
  const startTime = start.toLocaleString('fr-fr', { timeStyle: 'short' });
  const endTime = end.toLocaleString('fr-fr', { timeStyle: 'short' });
  const time = [startTime, endTime].join(' - ');

  return {
    start,
    end,
    time,
    breakDuration,
    isNight: start.getDate() !== end.getDate(),
  };
};

const mapMissionDTOToMission = (dto: MissionDTO): Mission => {
  const contract = dto.contract === 'Vacation' ? 'Vacation' : 'Indépendant';
  const backgroundColor = dto.contract === 'Infirmier libéral' ? '#e1ffef' : '#e1efff';

  return {
    id: dto.id,
    code: dto.code,
    status: dto.status,
    job: dto.job,
    contract,
    service: dto.service,
    unit: dto.unit,
    daySchedules: dto.daySchedules.map(mapDayScheduleDtoToDaySchedule),
    planning: dto.planning,
    assignedCarerFullName: dto.assignedCarerFullName,
    totalPrice: dto.totalPrice,
    feeQuoteUrl: dto.feeQuoteUrl,
    serviceDeliveryContractUrl: dto.serviceDeliveryContractUrl,
    serviceDeliveryQuoteUrl: dto.serviceDeliveryQuoteUrl,
    feeInvoiceUrl: dto.feeInvoiceUrl,
    serviceDeliveryInvoiceUrl: dto.serviceDeliveryInvoiceUrl,
    styles: { backgroundColor },
  };
};

const mapMissionsDTOToMissions = (dtos: MissionDTO[]): Mission[] => {
  return dtos.map(mapMissionDTOToMission);
};

const { VITE_FACILITIES_MISSIONS_CALENDAR_OUTBOUND_API_KEY } = import.meta.env;

const fetchMissions = async (facilityId: string, signal: GenericAbortSignal) => {
  const url = `/facilities/${facilityId}/missions`;

  const config = {
    headers: {
      'X-API-KEY': VITE_FACILITIES_MISSIONS_CALENDAR_OUTBOUND_API_KEY,
    },
    signal,
  };

  return httpService
    .get<MissionDTO[]>(url, config)
    .then((response) => mapMissionsDTOToMissions(response.data));
};

export default fetchMissions;

export type { Mission, DaySchedule };
