import { CalendarOptions, EventContentArg, EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import locale from '@fullcalendar/core/locales/fr';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import { IonSpinner } from '@ionic/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import MissionEventDayGrid from '@facilities/components/MissionsCalendar/MissionEventDayGrid';
import MissionEventDayGridCard from '@facilities/components/MissionsCalendar/MissionEventDayGridCard';
import MissionEventList from '@facilities/components/MissionsCalendar/MissionEventList';
import componentStyles from '@facilities/components/MissionsCalendar/MissionsCalendar.module.css';
import useFetchMissions from '@facilities/hooks/useFetchMissions';
import { DaySchedule, Mission } from '@facilities/services/fetchMissions';

type MissionsCalendarProps = {
  facilityId: string;
};

type MissionEventContentArg = EventContentArg & {
  event: EventImpl & { extendedProps: { mission: Mission; schedule: DaySchedule } };
};

const mapMissionToCalendarEvents = (mission: Mission): EventInput[] => {
  return mission.daySchedules.map((schedule, index) => {
    const { start, end } = schedule;
    // Creating a fake end date to avoid event to overflow over start date
    const fakeEndDate = schedule.isNight
      ? new Date(start.getFullYear(), start.getMonth(), start.getDate(), 23, 59, 59)
      : end;
    const title = [mission.assignedCarerFullName, mission.job, mission.contract]
      .filter(Boolean)
      .join(' - ');

    return {
      id: `${mission.id}-${index}`,
      title,
      start,
      end: fakeEndDate,
      extendedProps: { mission, schedule },
      className: 'mission-event',
      display: 'block',
      backgroundColor: mission.styles.backgroundColor,
      borderColor: mission.styles.backgroundColor,
      textColor: '#2c3e50',
    };
  });
};

const mapMissionsToCalendarEvents = (missions: Mission[] | undefined): EventInput[] => {
  if (!missions) {
    return [];
  }

  return missions.flatMap(mapMissionToCalendarEvents);
};

const renderDayGridMonthEvent = (eventInfo: MissionEventContentArg) => {
  return <MissionEventDayGrid eventInfo={eventInfo} />;
};

const renderIonCardEvent = (eventInfo: MissionEventContentArg) => {
  return <MissionEventDayGridCard eventInfo={eventInfo} />;
};

const renderListEvent = (eventInfo: MissionEventContentArg) => {
  return <MissionEventList eventInfo={eventInfo} />;
};

const getCalendarOptions = (events: EventInput[]): CalendarOptions => ({
  height: '100%',
  locale,
  firstDay: 1,
  plugins: [dayGridPlugin, listPlugin],
  initialView: 'dayGridWeek',
  views: {
    dayGridMonth: {
      dayMaxEvents: 3,
      eventContent: renderDayGridMonthEvent,
    },
    dayGridWeek: { eventContent: renderIonCardEvent },
    listWeek: { eventContent: renderListEvent },
  },
  headerToolbar: {
    start: 'prev,next today',
    center: 'title',
    end: 'dayGridMonth,dayGridWeek,listWeek',
  },
  buttonText: {
    dayGridTwoWeeks: '2 semaines',
    dayGridThreeDays: '3 jours',
  },
  events,
});

const MissionsCalendar = ({ facilityId }: MissionsCalendarProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'facilities' });

  const { data: missions, isLoading, isSuccess, isError } = useFetchMissions(facilityId);

  const events = mapMissionsToCalendarEvents(missions);

  const calendarOptions = getCalendarOptions(events);

  useEffect(() => {
    window.setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  });

  return (
    <>
      {isLoading && (
        <div className={componentStyles.spinnerContainer}>
          <IonSpinner />
        </div>
      )}
      {isError && <p className="ion-padding">{t('home.planning.errorMessage')}</p>}
      {isSuccess && <FullCalendar {...calendarOptions} />}
    </>
  );
};

export default MissionsCalendar;
