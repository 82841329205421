import {
  IonAlert,
  IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSkeletonText,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import HeaderIcon from '@carers/components/HeaderIcon/HeaderIcon';
import useGetUserAccount from '@carers/hooks/useGetUserAccount';
import { PunctualAvalaibilitiesTimespan } from '@carers/services/FetchUserAccount';
import { useUpdatePunctualAvailabilities } from '@carers/views/PunctualAvailabilitiesForm/hooks/useUpdatePunctualAvailabilities';
import { LabelValue } from '@shared/models/LabelValue';

import pageStyles from './PunctualAvailabilitiesForm.module.css';

const PunctualAvailabilitiesForm = () => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.availabilities' });
  const router = useIonRouter();
  const {
    mutate: updatePunctualAvailabilities,
    isPending,
    isError,
  } = useUpdatePunctualAvailabilities();

  const getDateLabel = (date: Date) => {
    return date.toLocaleDateString('fr-FR', {
      weekday: 'long',
      day: 'numeric',
      month: 'short',
    });
  };

  const { data: userAccount, isLoading } = useGetUserAccount();

  const [timespan, setTimespan] = useState<PunctualAvalaibilitiesTimespan>(
    PunctualAvalaibilitiesTimespan.DAY_AND_NIGHT,
  );

  const [selectedDates, setSelectedDates] = useState<Date[]>([]);

  useEffect(() => {
    if (!isLoading) {
      if (!userAccount) {
        return;
      }

      const { punctualAvailabilitiesTimespan, punctualAvailabilities } = userAccount;

      if (!!punctualAvailabilities) {
        setSelectedDates(punctualAvailabilities);
      }

      if (!!punctualAvailabilitiesTimespan) {
        setTimespan(punctualAvailabilitiesTimespan);
      }
    }
  }, [userAccount, isLoading]);

  const generateNextThirtyDays = (): LabelValue<Date>[] => {
    const dates: LabelValue<Date>[] = [];

    for (let i = 0; i < 30; i += 1) {
      const date = new Date();
      date.setDate(date.getDate() + i);
      dates.push({ value: date, label: getDateLabel(date) });
    }

    return dates;
  };

  const handleTimespanChange = (value: PunctualAvalaibilitiesTimespan) => {
    setTimespan(value);
  };

  const handleDateChange = (date: Date) => {
    const selectedDatesLabels = selectedDates.map((selectedDate) => getDateLabel(selectedDate));

    if (selectedDatesLabels.includes(getDateLabel(date))) {
      setSelectedDates(selectedDates.filter((d) => getDateLabel(d) !== getDateLabel(date)));
    } else {
      setSelectedDates([...selectedDates, date]);
    }
  };

  const submitPunctualAvailabilitiesForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updatePunctualAvailabilities({
      punctualAvailabilities: selectedDates,
      punctualAvailabilitiesFormTimespan: timespan,
    });
  };

  const returnToProfile = () => {
    router.push('/carers/profile');
  };

  return (
    <>
      <IonAlert
        isOpen={isError}
        header={t('alerts.title')}
        subHeader={t('alerts.subtitle')}
        buttons={[t('alerts.action')]}
        onDidDismiss={returnToProfile}
      />

      <IonLoading isOpen={isPending} message={t('validation')} />

      <IonPage>
        <IonHeader>
          <IonToolbar>
            <HeaderIcon slot="start" />
            <IonTitle>{t('pageTitle')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonItem
            className={`${pageStyles.noBorder} ${pageStyles.noBackground} ${pageStyles.describtion}`}
          >
            <IonLabel>{t('describtion')}</IonLabel>
          </IonItem>
          <form onSubmit={submitPunctualAvailabilitiesForm} id="updatePunctualAvailabilitiesForm">
            <IonItem className={`${pageStyles.noBorder} ${pageStyles.noBackground}`}>
              <IonLabel className={pageStyles.formQuestion}>{t('selectTimespan.label')}</IonLabel>
            </IonItem>
            {isLoading ? (
              <IonSkeletonText animated style={{ width: '100%', height: '100px' }} />
            ) : (
              <IonRadioGroup
                value={timespan}
                onIonChange={(e) => handleTimespanChange(e.detail.value)}
              >
                <IonGrid className="">
                  <IonRow className="ion-justify-content-between ion-padding-horizontal">
                    <IonCol size="12" sizeSm="4">
                      <IonItem className={`${pageStyles.radioButton} ${pageStyles.noBackground}`}>
                        <IonRadio value={PunctualAvalaibilitiesTimespan.DAY_ONLY}>
                          <IonLabel className="ion-padding-start">{t('timespans.days')}</IonLabel>
                        </IonRadio>
                      </IonItem>
                    </IonCol>
                    <IonCol size="12" sizeSm="4">
                      <IonItem className={`${pageStyles.radioButton} ${pageStyles.noBackground}`}>
                        <IonRadio value={PunctualAvalaibilitiesTimespan.NIGHT_ONLY}>
                          <IonLabel className="ion-padding-start">{t('timespans.nights')}</IonLabel>
                        </IonRadio>
                      </IonItem>
                    </IonCol>
                    <IonCol size="12" sizeSm="4">
                      <IonItem className={`${pageStyles.radioButton} ${pageStyles.noBackground}`}>
                        <IonRadio value={PunctualAvalaibilitiesTimespan.DAY_AND_NIGHT}>
                          <IonLabel className="ion-padding-start">{t('timespans.both')}</IonLabel>
                        </IonRadio>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonRadioGroup>
            )}
            <IonGrid>
              <IonItem className={`${pageStyles.noBorder} ${pageStyles.noBackground}`}>
                <IonLabel className={pageStyles.formQuestion}>{t('selectDates.label')}</IonLabel>
              </IonItem>
              {isLoading ? (
                <IonSkeletonText animated style={{ width: '100%', height: '100px' }} />
              ) : (
                <IonRow className="ion-padding-horizontal">
                  {generateNextThirtyDays().map((date) => (
                    <IonCol key={date.label} size="12" sizeSm="3">
                      <IonItem
                        key={date.label}
                        className={`${pageStyles.radioButton} ${pageStyles.noBackground}`}
                      >
                        <IonCheckbox
                          value={date.value}
                          checked={selectedDates
                            .map(getDateLabel)
                            .includes(getDateLabel(date.value))}
                          onIonChange={() => handleDateChange(date.value)}
                          className="ion-margin-horizontal checkbox-justify-start"
                          labelPlacement="end"
                        >
                          <IonLabel className="ion-text-left">{date.label}</IonLabel>
                        </IonCheckbox>
                      </IonItem>
                    </IonCol>
                  ))}
                </IonRow>
              )}
            </IonGrid>
          </form>
        </IonContent>

        <IonFooter className={pageStyles.footer}>
          <IonButton
            expand="block"
            type="submit"
            form="updatePunctualAvailabilitiesForm"
            className="ion-margin-horizontal"
          >
            {t('submit')}
          </IonButton>
          <IonButton
            expand="block"
            type="button"
            onClick={returnToProfile}
            className="ion-margin-horizontal"
            fill="outline"
          >
            {t('alerts.action')}
          </IonButton>
        </IonFooter>
      </IonPage>
    </>
  );
};

export default PunctualAvailabilitiesForm;
