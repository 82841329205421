import { GenericAbortSignal } from 'axios';

import { Mission } from '@carers/views/Missions/models/Missions';
import { MissionDTO } from '@carers/views/Missions/models/MissionsDTO';
import { httpService } from '@shared/httpService';
import {
  mapDayScheduleLightDTOToDayScheduleLight,
  mapFacilityDTOToFacility,
  mapLeadDTOToLead,
} from '@shared/utils/RequestAndMissionMapper';

const mapMissionDTOToMission = (dto: MissionDTO): Mission => {
  return {
    id: dto.id,
    contract: dto.contract,
    job: dto.job,
    rythms: dto.rythms,
    service: dto.service,
    unit: dto.unit,
    additionalInformation: dto.additionalInformation,
    netRemuneration: dto.netRemuneration,
    grossRemuneration: dto.grossRemuneration,
    minimumGrossRemuneration: null,
    start: dto.start,
    end: dto.end,
    facility: mapFacilityDTOToFacility(dto.facility),
    contractSignatureUrl: dto.contractSignatureUrl,
    contractSignedAt: dto.contractSignedAt,
    paymentDueOn: dto.paymentDueOn,
    lead: mapLeadDTOToLead(dto.lead),
    daySchedules: mapDayScheduleLightDTOToDayScheduleLight(dto.daySchedules),
    isCarerSiretIdEmpty: dto.isCarerSiretIdEmpty,
    schedulesProblemReportedAt: dto.schedulesProblemReportedAt,
    schedulesValidatedAt: dto.schedulesValidatedAt,
    status: dto.status,
    paymentNotificationSentAt: dto.paymentNotificationSentAt,
    tasksToCompleteCount: dto.tasksToCompleteCount,
  };
};

const getMissionById = async (id: string, signal: GenericAbortSignal) => {
  const url = `/missions/${id}`;

  return httpService.get<MissionDTO>(url, { signal }).then((response) => {
    return mapMissionDTOToMission(response.data);
  });
};

export default getMissionById;
