import { IonCard, IonCardContent, IonContent, IonIcon, IonPage, IonSpinner } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import Header from '@facilities/components/Header/Header';
import TasksAlert from '@facilities/components/TasksAlert/TasksAlert';
import { PREFERENCES_WIDGET_URL } from '@facilities/constants/retoolConstants';
import useGetFacility from '@facilities/hooks/useGetFacility';
import formatRetoolWidgetUrl from '@facilities/utils/utils-fn';
import containersStyles from '@facilities/views/containers.module.css';
import RetoolWidget from '@facilities/views/RetoolWidget/RetoolWidget';
import CardIcon from '@shared/assets/icons/card.svg';

import pageStyles from './Preferences.module.css';

const Preferences = () => {
  const { t } = useTranslation('app', { keyPrefix: 'facilities' });

  const { facilityId } = useParams<{ facilityId: string }>();
  const { data: facility, isSuccess, isLoading } = useGetFacility(facilityId);

  const preferencesUrl = formatRetoolWidgetUrl(PREFERENCES_WIDGET_URL, {
    facilityId,
  });

  const openFacilityCardUrl = () => {
    if (!!facility) {
      window.open(facility.cardUrl);
    }
  };

  return (
    <IonPage>
      <Header title={t('common.menu.preferences')} facility={facility} />
      {isLoading && <IonSpinner />}
      {isSuccess && (
        <IonContent className="ion-padding">
          <div className={containersStyles.fullWidthFlexContainer}>
            <TasksAlert />
            <IonCard className={pageStyles.facilityCard} onClick={openFacilityCardUrl}>
              <IonCardContent className="stack stack-horizontal">
                <IonIcon aria-hidden="true" src={CardIcon} />
                <div>
                  <p className="title">{t('preferences.facilityCard.title')}</p>
                  <p>{t('preferences.facilityCard.message')}</p>
                </div>
              </IonCardContent>
            </IonCard>
            <div className={containersStyles.fullHeightFlexContainer}>
              <RetoolWidget url={preferencesUrl} />
            </div>
          </div>
        </IonContent>
      )}
    </IonPage>
  );
};

export default Preferences;
