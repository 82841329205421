import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonChip,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
} from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import MissionStatusChip from '@carers/components/CardMission/MissionStatusChip';
import { DATE_CONFIG, useFormatDate } from '@carers/hooks/useFormatDate';
import useFormatRemuneration from '@carers/hooks/useFormatRemuneration';
import useFormatRythm from '@carers/hooks/useFormatRythm';
import { MissionLight } from '@carers/views/Missions/models/Missions';
import { RequestLight } from '@carers/views/Requests/models/Requests';
import BadgeIcon from '@shared/assets/icons/badge.svg';
import BusinessIcon from '@shared/assets/icons/business.svg';
import DateRangeIcon from '@shared/assets/icons/date_range.svg';
import ErrorOutlineIcon from '@shared/assets/icons/error_outline.svg';
import PaymentsIcon from '@shared/assets/icons/payments.svg';

import styles from './CardMission.module.css';

type RequestLightOrMissionLight = RequestLight | MissionLight;
type CardMissionProps = {
  mission: RequestLightOrMissionLight;
  saveScrollPosition?: () => void;
};

type InformationMissionType = {
  icon: string;
  label: string;
  value: string;
  valueLight?: string;
  shouldBeDisplayed: boolean;
};

const CardMission = ({ mission, saveScrollPosition }: CardMissionProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers' });
  const { iconRythm, wordingRythm } = useFormatRythm(mission.rythms);
  const { formatDate } = useFormatDate();
  const wordingRemuneration = useFormatRemuneration(
    mission.grossRemuneration,
    mission.netRemuneration,
    mission.minimumGrossRemuneration,
  );

  const isMissionWithStatus = (dto: RequestLight | MissionLight): dto is MissionLight =>
    'status' in dto;

  const informationMission: InformationMissionType[] = [
    {
      icon: BadgeIcon,
      label: t('missions.detail.job'),
      value: mission.job.label,
      shouldBeDisplayed: true,
    },
    {
      icon: BusinessIcon,

      label: t('missions.detail.facility'),
      value: mission.service.label,
      shouldBeDisplayed: true,
    },
    {
      icon: PaymentsIcon,
      label: t('missions.detail.remuneration'),
      value: wordingRemuneration.gross,
      valueLight: wordingRemuneration.net || '',
      shouldBeDisplayed: !!mission.grossRemuneration || !!mission.minimumGrossRemuneration,
    },
    {
      label: t('missions.detail.rythm'),
      icon: iconRythm,
      value: wordingRythm,
      shouldBeDisplayed: true,
    },
    {
      label: t('missions.detail.schedule'),
      icon: DateRangeIcon,
      value: `${formatDate(mission.start, DATE_CONFIG.MEDIUM_DATE_NO_YEAR)} - ${formatDate(
        mission.end,
      )}`,
      shouldBeDisplayed: true,
    },
  ].filter((item) => item.shouldBeDisplayed);

  return (
    <IonCard className={styles.ionCard}>
      <IonCardHeader className={styles.ionCardHeader}>
        <div>
          {isMissionWithStatus(mission) && (
            <MissionStatusChip label={mission.status.label} status={mission.status.value} />
          )}
          <IonChip color="primary" disabled>
            {mission.contract.label}
          </IonChip>
        </div>
        {isMissionWithStatus(mission) && mission.tasksToCompleteCount > 0 && (
          <IonChip color="danger" disabled>
            <IonIcon icon={ErrorOutlineIcon} />
            <IonLabel>
              {t('missions.detail.tasksToCompleteCount', {
                count: mission.tasksToCompleteCount,
              })}
            </IonLabel>
          </IonChip>
        )}
        <h5>{mission.facility.name}</h5>
      </IonCardHeader>
      <IonCardContent>
        <IonList className={styles.ionList}>
          {informationMission.map((item) => (
            <IonItem className="ion-no-margin ion-no-padding" lines="none" key={item.value}>
              <IonIcon slot="start" icon={item.icon} title={item.label} />
              <IonLabel className="ion-no-margin">
                {item.value} <span className={styles.labelLight}>{item.valueLight}</span>
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
        <IonButton
          fill="outline"
          expand="block"
          onClick={saveScrollPosition}
          routerLink={
            isMissionWithStatus(mission)
              ? `/carers/missions/${mission.id}`
              : `/carers/requests/${mission.id}?redirect=false`
          }
        >
          {t('common.actions.goToDetail')}
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};

export default CardMission;
