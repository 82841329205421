import { EventContentArg } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { IonButton, IonContent, IonIcon, IonTitle } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { DaySchedule, Mission } from '@facilities/services/fetchMissions';
import closeIcon from '@shared/assets/icons/close.svg';
import dayIcon from '@shared/assets/icons/day.svg';
import nightIcon from '@shared/assets/icons/night.svg';

type MissionEventContentArg = EventContentArg & {
  event: EventImpl & { extendedProps: { mission: Mission; schedule: DaySchedule } };
};

const MissionEventDetails = ({
  eventInfo,
  onDismiss,
}: {
  eventInfo: MissionEventContentArg;
  onDismiss: () => void;
}) => {
  const { t } = useTranslation('app', { keyPrefix: 'facilities' });

  const { mission, schedule } = eventInfo.event.extendedProps;
  const rythmIcon = schedule.isNight ? nightIcon : dayIcon;

  const missionCode = (
    <dl>
      <dt>{t('home.calendar.details.code')}</dt>
      <dd>{mission.code}</dd>
    </dl>
  );
  const missionJob = (
    <dl>
      <dt>{t('home.calendar.details.job')}</dt>
      <dd>{mission.job}</dd>
    </dl>
  );
  const missionService = (
    <dl>
      <dt>{t('home.calendar.details.service')}</dt>
      <dd>{mission.service}</dd>
    </dl>
  );
  const missionUnit = !!mission.unit && (
    <dl>
      <dt>{t('home.calendar.details.unit')}</dt>
      <dd>{mission.unit}</dd>
    </dl>
  );
  const missionContract = (
    <dl>
      <dt>{t('home.calendar.details.contract')}</dt>
      <dd>{mission.contract}</dd>
    </dl>
  );
  const missionTotalPrice = (
    <dl>
      <dt>{t('home.calendar.details.totalPrice')}</dt>
      <dd>
        {mission.totalPrice.toLocaleString('fr-fr', {
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'symbol',
        })}
      </dd>
    </dl>
  );
  const missionStatus = (
    <dl>
      <dt>{t('home.calendar.details.status')}</dt>
      <dd>{mission.status}</dd>
    </dl>
  );
  const missionPlanning = (
    <dl>
      <dt>{t('home.calendar.details.planning')}</dt>
      <dd>{mission.planning}</dd>
    </dl>
  );
  const serviceDeliveryQuoteUrl = !!mission.serviceDeliveryQuoteUrl && (
    <li>
      <IonButton href={mission.serviceDeliveryQuoteUrl} target="_blank">
        {t('home.calendar.details.serviceDeliveryQuote')}
      </IonButton>
    </li>
  );
  const serviceDeliveryContractUrl = !!mission.serviceDeliveryContractUrl && (
    <li>
      <IonButton href={mission.serviceDeliveryContractUrl} target="_blank">
        {t('home.calendar.details.serviceDeliveryContract')}
      </IonButton>
    </li>
  );
  const serviceDeliveryInvoiceUrl = !!mission.serviceDeliveryInvoiceUrl && (
    <li>
      <IonButton href={mission.serviceDeliveryInvoiceUrl} target="_blank">
        {t('home.calendar.details.serviceDeliveryInvoice')}
      </IonButton>
    </li>
  );

  return (
    <IonContent className="ion-padding">
      <IonTitle className="ion-text-center">
        <IonIcon className="rythmIcon" aria-hidden icon={rythmIcon} />
        {mission.assignedCarerFullName}
        <IonIcon className="closeIcon" aria-hidden icon={closeIcon} onClick={onDismiss} />
      </IonTitle>
      {missionCode}
      {missionJob}
      {missionService}
      {missionUnit}
      {missionContract}
      {missionTotalPrice}
      {missionStatus}
      {missionPlanning}
      <ul>
        {serviceDeliveryQuoteUrl}
        {serviceDeliveryContractUrl}
        {serviceDeliveryInvoiceUrl}
      </ul>
    </IonContent>
  );
};

export default MissionEventDetails;
