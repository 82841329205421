import { IonLabel, useIonRouter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import useFetchTasks from '@facilities/hooks/useFetchTasks';
import Alert from '@shared/components/Alert/Alert';

const TasksAlert = () => {
  const { t } = useTranslation('app', { keyPrefix: 'facilities' });

  const { facilityId } = useParams<{ facilityId: string }>();

  const { data: tasks } = useFetchTasks(facilityId);
  const tasksCount = tasks?.length || 0;

  const router = useIonRouter();

  const handleRedirectToTasks = () => {
    router.push(`/facilities/${facilityId}/tasks`);
  };

  if (tasksCount <= 0) {
    return null;
  }

  return (
    <Alert
      type="danger"
      title={t('tasks.alert.title', { plural: tasksCount > 1 ? 's' : '' })}
      className="clickable tasks-alert"
      onClick={handleRedirectToTasks}
    >
      <IonLabel>
        {t('tasks.alert.message', { count: tasksCount, plural: tasksCount > 1 ? 's' : '' })}
      </IonLabel>
    </Alert>
  );
};

export default TasksAlert;
