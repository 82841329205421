import { EventContentArg } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { useIonPopover } from '@ionic/react';

import MissionEventDetails from '@facilities/components/MissionsCalendar/MissionEventDetails';
import { DaySchedule, Mission } from '@facilities/services/fetchMissions';

type MissionEventContentArg = EventContentArg & {
  event: EventImpl & { extendedProps: { mission: Mission; schedule: DaySchedule } };
};

const MissionEventDayGrid = ({ eventInfo }: { eventInfo: MissionEventContentArg }) => {
  const { mission, schedule } = eventInfo.event.extendedProps;

  const [present, dismiss] = useIonPopover(MissionEventDetails, {
    eventInfo,
    onDismiss: (data: unknown, role: string) => dismiss(data, role),
  });

  const openPopover = () => present();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className="fc-event-main-frame" onClick={openPopover}>
      <div className="fc-event-time">{schedule.time}</div>
      <div className="fc-event-title-container">
        <div className="fc-event-title">{mission.assignedCarerFullName}</div>
      </div>
    </div>
  );
};

export default MissionEventDayGrid;
