import { GenericAbortSignal } from 'axios';

import { RequestLight } from '@carers/views/Requests/models/Requests';
import { RequestLightDTO, RequestsDTO } from '@carers/views/Requests/models/RequestsDTO';
import { RequestsFilters } from '@carers/views/Requests/models/RequestsFilters';
import { httpService } from '@shared/httpService';
import { InfiniteData } from '@shared/models/InfiniteData';
import convertGenericFilterObjectToApiUrlSearchParams from '@shared/utils/APISearchParamsFilter';
import { mapFacilityLightDTOToFacilityLight } from '@shared/utils/RequestAndMissionMapper';

const ITEMS_PER_PAGE = 12;

const getSearchParams = (nextItemId: string, filters: RequestsFilters) => {
  const urlSearchParams = convertGenericFilterObjectToApiUrlSearchParams(filters);

  urlSearchParams.set('size', ITEMS_PER_PAGE.toString());

  if (nextItemId) {
    urlSearchParams.set('nextId', nextItemId);
  }

  return urlSearchParams;
};

const mapRequestLightDTOToRequestLight = (dto: RequestLightDTO): RequestLight => {
  return {
    id: dto.id,
    facility: mapFacilityLightDTOToFacilityLight(dto.facility),
    contract: dto.contract,
    job: dto.job,
    rythms: dto.rythms,
    service: dto.service,
    netRemuneration: dto.netRemuneration,
    grossRemuneration: dto.grossRemuneration,
    minimumGrossRemuneration: dto.minimumGrossRemuneration,
    start: dto.start,
    end: dto.end,
  };
};

const mapRequestsDTOToInfiniteData = (dto: RequestsDTO): InfiniteData<RequestLight> => {
  return {
    data: dto.data.requests.map((requestDTO) => mapRequestLightDTOToRequestLight(requestDTO)),
    nextId: dto.metadata.requests.nextId,
  };
};

const getRequests = async (
  nextItemId: string,
  filters: RequestsFilters,
  signal: GenericAbortSignal,
) => {
  const url = `/requests?${getSearchParams(nextItemId, filters)}`;

  return httpService
    .get<RequestsDTO>(url, { signal })
    .then((response) => mapRequestsDTOToInfiniteData(response.data));
};

export default getRequests;
